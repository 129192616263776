.jobItem {
  background-color: var(--white);
  width: 100%;
  padding: 1em;
  border-radius: 5px;
  display: grid;
  /* justify-content:space-between; */
  align-items: center;
  grid-template-columns: 150px auto 1fr auto 80px;
  gap: 2em;
  margin: 1em 0;
  transition: 0.3s all ease;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);
}
.jobItem:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.separator {
  width: 1px;
  background-color: var(--primaryGreen);
  height: 100%;
}
.h1_half {
  font-size: 3.5em;
}
