.primary {
  background-color: var(--primaryGreen);
}

.primary:hover {
  opacity: 0.8;
  /* box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.165718); */
}

.secondary {
  border: 2px solid var(--primaryGreen);
  background-color: transparent;
}

.thirdLevel {
  padding: 0;
  background-color: transparent;
  color: var(--primaryGreen);
}
