:root {
  --neonGreen: #13cd83;

  --primaryGreen: #10b070;
  --secondaryGreen: #13cd83;

  --backgroundGreenDark: #c9e7d9;
  --backgroundGreenLight: #e4eee9;

  --textGreen: #085637;

  --darkGrey: #4e4e4e;
  --mediumGrey: #cccccc;
  --lightGrey: #f5f5f5;

  --black: #15171a;
  --white: #ffffff;

  --primaryBlue: #10046d;
  --secondaryBlue: #4285f4;
  --thirdBlue: #d6e7ff;

  --textColor: #585c61;
  --padding: 4em 7vw;

  --EVRGreen: #10b070;
  --primaryRed: #10b070;
  --opaqueRed: #c9e7d9;
  --LafargeGreen: #00965e;
  --opaqueLafargeGreen: #85c8ad;

  --smallText: 14px;
  --text: 16px;
  --h4: 20px;
  --h3: 24px;
  --h2: 42px;
  --h1: 75px;

  --radius: 5px;
}

@font-face {
  font-family: "Yantramanav";
  src: url("../src/Fonts/Yantramanav-Bold.ttf");
  /* IE9 Compat Modes */
  src: url("../src/Fonts/Yantramanav-Bold.ttf") format("truetype");
  /* Safari, Android, iOS */
}

@font-face {
  font-family: "Yantramanav-Light";
  src: url("../src/Fonts/Yantramanav-Light.ttf");
  /* IE9 Compat Modes */
  src: url("../src/Fonts/Yantramanav-Light.ttf") format("truetype");
  /* Safari, Android, iOS */
}

@font-face {
  font-family: "Inter-Medium";
  src: url("../src/Fonts/Inter-Medium.ttf");
  /* IE9 Compat Modes */
  src: url("../src/Fonts/Inter-Medium.ttf") format("truetype");
  /* Safari, Android, iOS */
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: var(--lightGrey); */
  font-size: var(--text);
  width: 100vw;
  overflow-x: hidden;
}

#root {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
  height: 2px;
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: var(--primaryGreen);
}

.sectionIntro {
  position: relative;
  z-index: 1;
}

.selectLangBTN {
  box-shadow: 0px 0px 0px 0px rgba(80, 80, 80, 0.2);
  transition: 0.2s all ease;
}

.selectLangBTN:hover {
  box-shadow: 0px 0px 10px 10px rgba(80, 80, 80, 0.2);
  cursor: pointer;
}

.scene-tooltip div .imgAndName {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.arcContentWrapper {
  padding: 1em;
  border-radius: 5px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: relative;
  left: -50%;
  /* top:-6em!important; */
  width: 40vw;
  background-color: rgba(96, 110, 83, 0.46);
}

.arcGrid {
  padding-top: 1em;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1em;
}

.arcGrid div img {
  object-fit: cover;
  width: 80px;
  height: 80px;
}

.scene-tooltip div p {
  color: "var(--white)";
}

.scene-tooltip div h2 {
  margin-bottom: 0.2em;
}

.scene-tooltip div h4 {
  margin-top: 0.2em;
}

.rangeWrapper {
  background-color: #2c0101;
  color: "var(--white)";
  padding: 3em 3em 7vw 7vw;
  margin-right: 1em;
  position: relative;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.rangeSquareTop {
  width: 4em;
  height: 4em;
  background-color: #13cd83;
  position: absolute;
  top: -1.5em;
  right: 5em;
}

.rangeSquareBottom {
  width: 4em;
  height: 4em;
  background-color: #9a0904;
  position: absolute;
  bottom: -4em;
  left: 0;
}

.rangeWrapper h2 {
  font-size: 28px;
}

.rangeWrapper input[type="number"],
.consultingWrap input[type="number"] {
  font-size: 5em;
  background: transparent;
  color: "var(--white)";
  border: none;

  width: 3em;
  display: inline-block;
  margin-bottom: 0.5em;
}

.rangeWrapper input[type="number"] {
  border-bottom: 1px solid #ff3f40;
}

.consultingWrap input[type="number"] {
  border-bottom: 1px solid var(--primaryGreen);
}

.EVRPricingGrid {
  display: grid;
  grid-template-columns: 4fr 1.5fr;
  gap: 2em;
  align-items: center;
}

.consultingWrap {
  background-color: #075e34;
  color: "var(--white)";
  position: relative;
  left: -7vw;
  width: calc(100% - 7vw);
  height: fit-content;
  padding: 2em 7em 2em 7vw;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.consultingOptions {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}

.consultingOptions p {
  color: "var(--white)";
  opacity: 0.8;
  display: flex;
}

.consultingOptions p svg {
  margin-left: 0.5em;
}

.inRange {
  font-weight: bolder;
  text-transform: uppercase;
  opacity: 1 !important;
}

/*
=====
CORE STYLES
=====
*/

.toggle {
  --uiToggleSize: var(--toggleSize, 1.25rem);
  --uiToggleBorderWidth: var(--toggleBorderWidth, 2px);
  --uiToggleColor: var(--toggleColor, #00bb6f);

  display: var(--toggleDisplay, inline-flex);
  position: relative;
}

.toggle__input {
  /*
  The pattern by Sara Soueidan https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/
  */
  width: var(--uiToggleSize);
  height: var(--uiToggleSize);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}

/*
1. Calculation of the gap for the custom checkbox
*/

.toggle__label {
  display: inline-flex;
  min-height: var(--uiToggleSize);
  padding-left: calc(var(--uiToggleSize) + var(--toggleIndent, 0.4em));
}

.toggle__input:not(:disabled) ~ .toggle__label {
  cursor: pointer;
}

/*
1. Ems helps to calculate size of the checkbox
*/

.toggle__label::after {
  content: "";
  box-sizing: border-box;
  width: 1em;
  height: 1em;
  font-size: var(--uiToggleSize); /* 1 */

  background-color: transparent;
  border: var(--uiToggleBorderWidth) solid var(--uiToggleColor);

  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.toggle__input:checked ~ .toggle__label::after {
  background-color: var(--uiToggleColor);
}

.toggle__text {
  margin-top: auto;
  margin-bottom: auto;
}

/*
The arrow size and position depends from sizes of square because I needed an arrow correct positioning from the top left corner of the element toggle

1. Ems helps to calculate size and position of the arrow
*/

.toggle__label::before {
  content: "";
  width: 0;
  height: 0;
  font-size: var(--uiToggleSize); /* 1 */

  border-left-width: 0;
  border-bottom-width: 0;
  border-left-style: solid;
  border-bottom-style: solid;
  border-color: var(--toggleArrowColor, #fff);

  position: absolute;
  top: 0.5428em;
  left: 0.25em;
  z-index: 3;

  transform-origin: left top;
  transform: rotate(-40deg) skew(10deg);
}

.toggle__input:checked ~ .toggle__label::before {
  --uiToggleArrowWidth: var(--toggleArrowWidth, 2px);

  width: 0.4em;
  height: 0.2em;
  border-left-width: var(--uiToggleArrowWidth);
  border-bottom-width: var(--uiToggleArrowWidth);
}

/*
States
*/

/* focus state */

.toggle:focus-within {
  outline: var(--toggleOutlineWidthFocus, 3px) solid
    var(--toggleOutlineColorFocus, currentColor);
  outline-offset: var(--toggleOutlineOffsetFocus, 5px);
}

/* disabled state */

.toggle__input:disabled ~ .toggle__label {
  opacity: var(--toggleOpacityDisabled, 0.24);
  cursor: var(--toggleCursorDisabled, not-allowed);
  user-select: none;
}

/*
=====
PRESENTATION STYLES
=====
*/

/* 
The demo skin
*/

.toggle__label::after {
  border-radius: var(--toggleBorderRadius, 2px);
}

/* 
The animation of switching states
*/

.toggle__input:not(:disabled) ~ .toggle__label::before {
  will-change: width, height;
  opacity: 0;
}

.toggle__input:not(:disabled):checked ~ .toggle__label::before {
  opacity: 1;
  transition: opacity 0.1s ease-out 0.25s, width 0.1s ease-out 0.5s,
    height 0.2s ease-out 0.3s;
}

.toggle__input:not(:disabled) ~ .toggle__label::after {
  will-change: background-color;
  transition: background-color 0.2s ease-out;
}

/*
=====
SETTINGS
=====
*/

.page__custom-settings {
  --toggleColor: #690e90;
  --toggleOutlineColorFocus: #690e90;
  --toggleSize: 2rem;
}

/*
=====
DEMO
=====
*/

.page {
  box-sizing: border-box;
  max-width: 45rem;
  padding: 2.75rem 2rem 2.5rem;
  margin: auto;

  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.page__section {
  display: flex;
  flex-wrap: wrap;
  font-weight: 700;
}

.page__section:nth-child(n + 2) {
  margin-top: 3rem;
}

.page__custom-settings {
  --pageToggleTextFontSize: 1.25rem;
}

.page__name {
  width: 100%;
}

.page__name::first-letter {
  text-transform: uppercase;
}

.page__toggle {
  margin-top: 1.25rem;
  margin-right: 1.5rem;
  font-size: var(--pageToggleTextFontSize);
}

input[type="range"] {
  -webkit-appearance: none;
  margin-right: 15px;
  width: 100%;
  height: 10px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient(to right, #ff3f40, #9a0904);
  background-size: 70% 100%;
  background-repeat: no-repeat;
}

.rangeGreen {
  background-image: linear-gradient(to right, #5dec3d, #049a1d) !important;
}

/* Input Thumb , this is the circle on top of the slider to move it */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #ffffff;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
  transition: 0.3s ease all;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #10b070;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #10b070;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
  transition: 0.3s ease all;
}

input[type="range"]::-webkit-slider-thumb:hover {
  transform: scale(1.3);
}

input[type="range"]::-moz-range-thumb:hover {
  transform: scale(1.3);
}

input[type="range"]::-ms-thumb:hover {
  transform: scale(1.3);
}

/* Input Track */
input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

button {
  font-weight: 600;
  font-size: var(--text);
  padding: 1em 2em;
  border-radius: 4px;
  /* box-shadow: 0px 6px 10px rgba(36, 36, 36, 0.165718); */
  border: none;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
}

button:focus {
  outline: none;
}

button:hover {
  cursor: pointer;
}

button svg g line {
  transform: translateX(-6px);
  opacity: 0;
  transition: all 0.3s ease;
}

button:hover svg g line {
  transform: translateX(0px);
  opacity: 1;
}

button svg g polyline {
  transform: translateX(-6px);
  transition: all 0.3s ease;
}

button:hover svg g polyline {
  transform: translateX(0px);
}

a {
  text-decoration: none;
  color: var(--black);
}

h1 {
  font-family: "Yantramanav";
  margin: 0;
  line-height: 1.1em;
  font-size: var(--h1);
}

h2 {
  font-family: "Yantramanav";
  margin: 0;
  line-height: 1.1em;
  font-size: var(--h2);
}

h3 {
  font-size: var(--h3);
  margin-top: 1em;
  margin-bottom: 0.3em;
}

h4 {
  font-family: "Yantramanav";
  font-size: var(--h4);
  margin: 0;
  margin-top: 1em;
  margin-bottom: 0.3em;
}

p {
  margin: 0;
  font-size: var(--text);
  color: var(--textColor);
  line-height: calc(var(--text) * 1.6);
  font-weight: 400;
}

.nav {
  padding: 1em 7vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
}

.navDark {
  background-color: var(--black);
  border-bottom: 2px solid var(--primaryGreen);
}
.navDark p {
  color: var(--white);
}

.navLight {
  background-color: var(--white);
  /* border-bottom: 2px solid var(--primaryGreen); */
}

.navLight p {
  color: var(--black);
}

.buttonContainer {
  margin: 1em 2em 1em 0;
}

.nav .links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav .links a {
  margin: 0 1em;
}

.nav .links a .hoverItem p {
  color: var(--black);
  font-weight: 500;
}

.nav .links a .hoverItem p:hover {
  color: var(--primaryGreen);
  font-weight: 800;
}

.nav .links div .hoverItem p {
  color: var(--black);
  font-weight: 500;
}

.nav .links div .hoverItem p:hover {
  color: var(--primaryGreen);
  font-weight: 800;
}

.nav-buttons a .secondary {
  margin-right: 15px;
  padding: 8px 20px;
}
.nav-buttons a button {
  padding: 10px 20px;
}

.languageButtons {
  display: flex;
}

.languageSingleButton {
  background-color: transparent;
  padding: 0 1em;
}

.secondMenuItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 999;
  padding: 1em;
  border-right: solid 1px var(--backgroundGreenDark);
}

.secondMenuItem:hover {
  background-color: var(--backgroundGreenDark);
}

.bolder {
  font-family: "Yantramanav";
  font-weight: bolder;
}

.lighter {
  font-family: "Yantramanav-Light";
}

.sectionIntro .smallTitle {
  font-size: 2.5vw;
}

.landingTextsWrapper {
  padding: 7em 0vw 0 7vw;
  position: relative;
  min-height: 200px;
  display: flex;
  background-image: url("https://res.cloudinary.com/efer/image/upload/v1686668837/VivaDrive/Screens/Frame_1024_lpwgl4-min_spzrwx.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.landingImageWrapper {
  width: 50%;
  margin-left: 5vw;
  margin-bottom: 5vw;
}

.landingImageWrapper img {
  width: 130%;
}

.mediaAssetsAbout {
  display: flex;
  align-items: center;
  margin: 3em 0;
}

.mediaAssetsAbout > div {
  width: 50%;
}

.benefitsColumns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2em;
  margin: 4em 0 0 0;
}

.landingTexts {
  width: 40%;
  z-index: 10;
  position: relative;
}

.jumpTo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 5em;
}

.jumpToText {
  color: var(--primaryGreen);
  display: flex;
  justify-content: center;
}

.jumpToText > a > h3 {
  color: var(--primaryGreen);
  margin: 1em;
}

.jumpToText > a > h3:hover {
  color: var(--primaryGreen);
  margin: 1em;
}

.jumpToText a:hover {
  color: var(--primaryGreen);
}

.landingTexts h1 {
  font-size: 6vw;
}

.textGreen {
  color: var(--primaryGreen);
}

.SmallMediaImage {
  width: 100%;
  border-radius: var(--radius);
  object-fit: cover;
  height: 30vh;
}

.SmallMediaText {
  padding: 0em 1em 0.5em 1em;
  text-align: left;
}

.SmallMediaText p {
  color: #929292;
}

.SmallMediaTextButton {
  display: flex;
  justify-content: left;
}

.productContent h1 {
    font-size: 4.8em;
    font-weight: 900;
  }

.productContent h2 {
  color: var(--primaryGreen);
    font-size: 3.3em;
    font-weight: 700;
  }

.ecosystem-mobile {
  display: none;
}

.Section3DWrapper {
  display: grid;
  position: relative;
}

.Section3DWrapper .section h2,
.Section3DWrapper .section p {
  width: 50%;
}

.Section3DWrapper .model3D {
  position: absolute;
  width: 65vw;
  height: 100%;
  right: 0;
  bottom: 0;
  /* overflow:hidden; */
}

.Section3DWrapper .sideImage {
  position: absolute;
  right: -15%;
  padding-top: 7em;
}

.sideImage img {
  object-fit: contain;
  width: 55vw;
}

.model3D canvas {
  width: 95vw;
}

.grid3DSection {
  display: grid;
  gap: 2em;
  margin-top: 5em;
  color: "var(--white)";
}

.grid3Dicons {
  display: flex;
  align-items: center;
  width: 100%;
}

.grid3Dicons h2{
  padding-left: 1em;
  color: var(--black);
  font-weight: 500;
  font-size: 1.8em;
}

  .columnsAdvantages{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
.advantagesText{
  color: var(--white)
}

.grid3DSection p {
  width: 35%;
}

body {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
}

.rectangle {
  background-color: #10b070;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 50px;
  box-shadow: 0 0 20px rgba(16, 176, 112, 0.8); /* Add box-shadow */
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text h2,
p {
  flex-grow: 1;
  color: white;
}
.text p {
  flex-grow: 1;
  color: white;
}

h2,
p {
  color: black;
  margin: 0;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: white;
  color: #10b070;
  text-decoration: none;
  border-radius: 5px;
  font-size: 18px;
}

.sectionIntro h2 {
  width: 80%;
  margin-bottom: 0.5em;
  position: relative;
}

.sectionIntro p {
  width: 55%;
  font-weight: 400;
}

.socialProofTab {
  display: flex;
  align-items: center;
  height: 130px;
  z-index: 1;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
}

.MentionsBig {
  display: grid;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  margin-bottom: 4em;
  color: var(--black);
}

.MentionsBig .div img .blogCardImg {
  height: 100em;
}

.MentionsBigText {
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: flex-start;
}

.MentionsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3em;
  padding: 0em;
}

.EUGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3em;
  align-items: center;
  padding: 0em;
}

.projectContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--black);
  padding: 2em;
}

.SmallMediaImage {
  max-height: 10em;
}

.projectContent:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: var(--radius);
  color: var(--darkGrey);
}

/* canvas:hover{
  cursor: grab;
} */
canvas:active {
  cursor: grabbing;
}

.squareWrapperTop {
  position: absolute;
  top: 0;
  right: 7vw;
  width: 50%;
  text-align: right;
}

.square {
  position: relative;
  float: right;
  width: 50px;
  height: 50px;
  z-index: 12;
  top: -0.5em;
}

.squareTop {
  position: relative;
  top: -2em;
  z-index: 0;
}

.squareBack {
  z-index: -1;
}

.subMenu {
  background-color: var(--white);
  padding: 2em;
  border-radius: 5px;

  /* display:none; */
  transform: translateY(-100%) translateX(-25%);
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 0;
}

.hoverItem:hover .subMenu {
  /* display:block; */
  transform: translateY(0) translateX(-25%);
  opacity: 1;
}

.hoverItem p {
  transition: 0.2s ease all;
  color: var(--white);
}

.hoverItem:hover p {
  font-weight: 500;
  color: var(--secondaryGreen);
}

.textBackground {
  background-color: var(--white);
  padding: 0.2em 0.3em 0 0.3em;
  display: inline-block;
  margin: 0;
  position: relative;
}

.socialProofText {
  display: flex;
  justify-content: center;
  background-color: var(--backgroundGreenLight);
  color: var(--black);
  padding: 1em 0 0 0;
}

.iconSocial path {
  transition: all 0.5s ease;
  fill: var(--primaryGreen);
}

.iconSocial:hover path {
  fill: var(--secondaryGreen);
}

.footer {
  display: grid;
  grid-template-columns: 3fr 1fr 1.5fr 1fr;
}

.footerSocialMedia a {
  color: var(--black);
}
.footerSocialMedia a:hover {
  color: var(--black);
}

.firstP {
  font-weight: 400;
  font-size: var(--smallText);
  width: 60%;
  margin-bottom: 1.5em;
}

.teamGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2em;
  margin: 4em 0 0 0;
}

.singleTeamMember:hover .memberImg {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.role {
  display: inline-block;
}

.memberTextGrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.memberImg {
  transition: 0.5s;
  object-fit: cover;
  max-width: 12em;
  height: 100%;
  margin-bottom: 1em;
  border-radius: 7px;
}

.pricingWrapperGrid {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 0em;
}

.mediaAssetContainer {
  background-color: #cce3dc;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2em;
  padding: 3em;
  border-radius: 7px;
  width: 50%;
}

.PricingGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2em;
  width: 100%;
  position: relative;
}

.pricingListItem {
  padding: 0.5em 0;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.columnImg {
  width: 7vw;
  height: 7vw;
}

.columnImgAdvantages{
  width: 10vw;
  height: 10vw;
}

.priceColumn {
  padding: 2em 1em;
  border-radius: 0.5em;
}

.priceColumn p {
  transition: 0.5s ease all;
}

.priceColumn--active {
  color: var(--white);
}

.priceColumn--active p {
  color: var(--white);
}

.flashAnimation {
  color: var(--secondaryBlue);
  font-size: 3em;
}

.inlineBlock {
  display: inline-block;
}

.lineThrought {
  text-decoration: line-through;
  opacity: 0.8;
}

.WorkWithUsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
  grid-template-areas: "benefits form";
  margin-bottom: -10em;
}

.benefits {
  grid-area: benefits;
  padding: 15em 2em 2em 2em;
  border-radius: 5px;
  position: relative;
  top: -14em;
  z-index: 1;
}

.withUsForm {
  grid-area: form;
  box-shadow: 0 0 10px 5px #00000022;
  padding: 2em;
  background-color: var(--white);
  border-radius: 5px;
  position: relative;
  top: -18em;
  z-index: 10;
}

form label {
  display: block;
}

form input,
form textarea,
select {
  font-size: 16px;
  width: calc(100% - 2em);
  border: none;
  /* background-color:#e0e4ea; */
  background-color: var(--lightGrey);
  padding: 1em;
  margin: 0.3em 0 1.3em 0;
  border-radius: 2px;
  transition: 0.3s ease all;
}

/* select {
  width: 100%;
} */

form textarea {
  height: 6em;
  resize: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  background-color: #d4d4d4;
}

.blogContent {
  padding: 0em 1em 0.5em 1em;
}

.blogCardImg {
  height: 100%;
  width: 100%;
}

.blogCard {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background-color: #fff;
  margin-bottom: 4em;
  height: 22em;
}

.blogCard h3 {
  text-align: left;
  color: var(--black);
}

/*Colored Squares*/
.squareRed {
  position: relative;
  top: -1.5em;
  right: 0;
  width: 3.5vw;
  height: 3.5vw;
  background-color: var(--secondaryBlue);
  float: right;
}

.squareBlue {
  position: relative;
  top: -1em;
  right: 0;
  width: 3.5vw;
  height: 3.5vw;
  background-color: var(--secondaryBlue);
  float: right;
}

.squareGreen {
  position: relative;
  top: -0.5em;
  right: 0;
  width: 3.5vw;
  height: 3.5vw;
  background-color: var(--secondaryGreen);
  float: right;
}

/*Colored Squares Vertical adjusted Left*/
.squaresVerticalLeft {
  display: flex;
  flex-direction: column;
  left: 0;
  float: left;
  position: absolute;
  z-index: -2;
}

.squareRedLeft {
  position: relative;
  left: -4.5em;
  width: 50vh;
  height: 4vw;
  background-color: var(--secondaryBlue);
  float: left;
}

.squareBlueLeft {
  position: relative;
  left: -4em;
  width: 50vh;
  height: 4vw;
  background-color: var(--secondaryBlue);
  float: left;
}

.squareGreenLeft {
  position: relative;
  left: -3em;
  width: 50vh;
  height: 4vw;
  background-color: var(--secondaryGreen);
  float: left;
}

/*Colored Squares Vertical adjusted Right*/
.squaresVerticalRight {
  float: right;
  position: absolute;
  top: 20em;
  float: right;
  right: 0;
  z-index: -1;
}

.squareBlueRight {
  position: relative;
  right: -4em;
  width: 50vh;
  height: 4vw;
  background-color: var(--secondaryBlue);
}

.squareGreenRight {
  position: relative;
  right: -2.5em;
  width: 50vh;
  height: 4vw;
  background-color: var(--primaryGreen);
}

.formBg {
  box-shadow: 0 0 10px 5px #00000022;
  padding: 2em;
  background-color: var(--white);
  border-radius: 5px;
  z-index: 10;
}

.nav a .logo img {
  height: 45px;
}

.contactUs {
  text-align: left;
  padding-bottom: 4em;
}

/*Products Grid Section*/
.columnSection {
  padding: var(--padding);
}

.columnSection ul li:nth-last-child(odd) .productGrid .img {
  grid-area: 1;
}

.productGrid {
  margin: 3em 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  align-items: center;
}

.featuresGrid {
  margin: 3em 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  align-items: center;
}

/* .productGrid svg {
  padding-right: 3em;
} */

.productGrid .img {
  overflow: hidden;
}

.productGrid .img img {
  width: 100%;
}

a .productGrid .productContent {
  color: var(--black);
  padding: 2em;
}

a .featuresGrid .featuresBox {
  padding: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #d7e7e0a4;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

a .featuresGrid .useCasesBox {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--black);
  padding: 2em;
  overflow: hidden;
}

a .featuresGrid .useCasesBox:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

a .featuresGrid .featuresContent {
  color: var(--black);
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

a .featuresGrid .featuresBox:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.productGrid .thirdLevel {
  padding: 0;
  background-color: transparent;
  font-size: 1.5em;
}

.productIcon {
  width: 50%;
  margin: 0;
  padding: 0em;
}

.moreProductsColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3em;
}

.moreProductsColumns a {
  color: var(--black);
}

.extraProduct {
  border-radius: 7px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background-color: "var(--white)";
  padding: 2em;
  margin-top: 2em;
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
}

.extraProductRight a {
  color: var(--black);
}

.extraProductLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-right: 2em;
}

.container {
  height: 35vh;
}

.extraProductLeft .productIcon {
  width: 100%;
  margin-bottom: 2em;
  padding: 0em;
}

.extraProductRight {
  display: flex;
  flex-direction: column;
}

.extraProductRight button {
  font-size: 1.4em;
}

.mainTitle {
  font-size: 4em;
}

.landingTexts p {
  width: 45%;
}

.landingTexts h5 {
  margin: 2em 0;
  font-size: 1.5em;
}

.buttonMargin {
  margin-left: 2em;
}

.projectContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 2em 20em;
}

.projectContainer :nth-last-child(even) .Project {
  text-align: right;
}

.projectsSection {
  z-index: -1;
}

.projectSingleImage {
  height: 10vh;
  padding: auto 0;
}

.Project {
  text-align: left;
  margin: 3em 0;
}

.euCompanies {
  display: flex;
  justify-content: center;
}

.euCompanies img {
  flex-direction: column;
  align-items: center;
  width: 10em;
  margin: 0 2.5vw;
}

.awards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.awards img {
  flex-direction: column;
  align-items: center;
  width: 10%;
  height: 10%;
  margin: 0 3.5vw;
}

.projectSingleImageMobile {
  display: none;
}

/*LARGE SCREENS*/
@media only screen and (min-width: 1800px) {
  .landingTexts {
    margin-bottom: 4em;
  }
  .landingTexts h4 {
    font-size: 4.5vw;
    margin: 1em 0 0.5em;
  }
  .landingTexts h5 {
    font-size: 3vw;
  }

  .landingTexts span {
    font-size: 2vw;
    margin: 0;
  }

  .landingTexts h3 {
    font-size: 1.5vw;
  }

  .landingTexts a button {
    width: 17vw;
    height: 3.5vw;
    margin: 2em 0;
    font-size: 1.7em;
  }

  .mainTitle {
    font-size: 2em;
  }

  .benefitsColumns {
    margin: 8em 0 0 0;
  }

  .Section3DWrapper .sideImage {
    position: absolute;
    right: -5%;
    padding-top: 1em;
  }

  .sideImage img {
    object-fit: contain;
    width: 50vw;
  }
}

/*MOBILE VERSION*/
@media only screen and (max-width: 800px) {
  .buttonMargin {
    display: block;
    margin-left: 0;
    margin-top: 2em;
  }

  .rectangle {
    width: 100%;
  }

  .rectangle .content {
    display: inline-block;
    text-align: center;
  }

  .rectangle .content a button {
    margin: 1em;
  }

  #blog {
    margin-top: 7em;
  }

  .productNav {
    padding: 4em 0 1em 7vw;
    display: flex;
  }

  .productNav .links {
    overflow-x: scroll;
    padding-right: 1em;
    display: none;
  }

  .productNav .links::-webkit-scrollbar-thumb {
    background: var(--black);
  }

  .productContent .buttonContainer {
  margin: 0;
   display: inline-flex;
}

  .arcContentWrapper {
    left: auto;
    top: -6em !important;
    width: 70vw;
    background-color: rgba(96, 110, 83, 0.46);
  }

  .jumpTo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .jumpToText {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .jumpToText > a > h3 {
    color: var(--primaryGreen);
    margin: 0.4em;
  }

.productContent{
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

  .MentionsGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em;
    align-items: center;
    padding: 0em;
  }

  .MentionsBig {
    display: flex;
    flex-direction: column;
  }

  .MentionsBigText {
    align-items: center;
    text-align: center;
  }

  .SmallMediaImage {
    height: 15vh;
  }

  .SmallMediaText {
    text-align: center;
    margin-bottom: 5em;
  }

  .SmallMediaTextButton {
    display: flex;
    justify-content: center;
  }

  .mainTitle,
  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 2em;
  }

  .landingTexts p {
    width: auto;
  }

  .sectionIntro .smallTitle {
    font-size: 10vw;
    text-align: center;
  }

  .socialProofText h2 {
    font-size: 1.2em;
  }

  .EVRPricingGrid {
    grid-template-columns: 1fr;
  }

  .socialProofTab {
    height: 100px;
  }

  .consultingWrap {
    overflow: hidden;
    left: -7vw;
    width: calc(100vw - 7vw - 7em);
  }

  .columnMob {
    width: calc(100% - 14vw);
  }

  .consultingOptions p {
    font-size: 14px;
  }

  .inRange {
    font-size: 12px;
  }

  .columnImg {
    height: 40%;
    width: 40%;
  }

  .columnImgAdvantages{
    height: 50%;
    width: 50%;
}

  .pricingWrapperGrid {
    grid-template-columns: 1fr;
  }

  /*Colored Squares*/
  .squareRed {
    position: relative;
    top: -1em;
    right: 0;
    width: 6.5vw;
    height: 6.5vw;
    background-color: var(--secondaryBlue);
    float: right;
  }

  .squareBlue {
    position: relative;
    top: -0.5em;
    right: 0;
    width: 6.5vw;
    height: 6.5vw;
    background-color: var(--secondaryBlue);
    float: right;
  }

  .squareGreen {
    top: 0em;
    right: 0;
    width: 6.5vw;
    height: 6.5vw;
  }

  .mediaAssetsAbout {
    flex-direction: column;
  }

  .mediaAssetsAbout > div {
    width: 100%;
    align-items: center;
    text-align: center;
    font-size: 13px;
  }
  .mediaAssetContainer {
    align-items: center;
    margin: 2em;
    padding: 2em;
    width: 90%;
  }

  /*Product Grid*/
  .productGrid {
    display: flex;
    flex-direction: column;
    background-color: color;
    padding: 2em;
    position: relative;
    bottom: 5px;
  }

  .productIcon {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .productGrid .productIcon svg {
    padding: 0em;
  }

  /*Mobile Menu*/
  .burger {
    z-index: 111;
  }

  .burger:hover {
    cursor: pointer;
  }

  .burger:hover .burgerTopLine {
    grid-template-columns: 3fr 1fr;
  }

  .burger--open:hover .burgerTopLine {
    grid-template-columns: 1fr;
  }

  .burger--open .burgerTopLine {
    grid-template-columns: 1fr;
  }

  .burger--open .burger--greenLine {
    top: 0.7em;
    position: relative;
    transform: rotate(45deg);
  }

  .burger--open .burger--blackLine--hide {
    display: none;
  }

  .burger--open .burger--blackLine {
    position: relative;
    transform: rotate(-45deg);
  }

  .burger--lines {
    transition: 0.3s ease all;
  }

  .burgerTopLine {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5px;
    transition: 0.5s ease all;
  }

  .mobileMenu {
    position: fixed;
    top: 0;
    left: 0;
    background-color: "var(--white)";
    width: 100%;
    height: 100%;
    z-index: 9999999;
    transform: translateX(100%);
    transition: 0.5s ease all;
    color: var(--black);
  }

  .burger--open {
    z-index: 9999999999;
    position: fixed;
    right: 35px;
  }

  .mobileMenu--active {
    background-color: var(--black);
    transform: translateX(0%);
  }

  .ecosystem {
    display: none;
  }
  .ecosystem-mobile {
    display: flex;
  }

  .withUsForm {
    top: 0;
  }

  .WorkWithUsGrid {
    position: relative;
    top: -9em;
    grid-template-columns: 1fr;
    grid-template-areas: "form" "benefits";
  }

  .boxesLetsWork {
    display: none;
  }

  .workP {
    width: 100% !important;
  }

  .PricingGrid {
    grid-template-columns: 1fr;
    text-align: center;
    position: relative;
    left: 0;
    width: 100%;
  }

  .PricingGrid .pricingListItem {
    justify-content: center;
  }

  .role {
    display: block;
  }

  .footer {
    grid-template-columns: 1fr 1fr;
    text-align: center;
  }

  .firstP {
    margin: auto;
  }

  .nav .links {
    gap: 1em;
  }

  .nav a .logo {
    width: 100%;
  }

  .nav a .logo img {
    width: 100%;
  }

  .landingTextsWrapper {
    padding: 2em 7vw;
    height: auto;
    text-align: center;
    flex-direction: column;
    background-image: url("https://res.cloudinary.com/efer/image/upload/v1687879590/Frame_1024_lpwgl4-min_spzrwx_1_yhq1hd.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .landingTexts .mainTitle {
    font-size: 2em;
  }

  .landingTexts h4 {
    font-size: 1em;
  }

  .landingImageWrapper {
    width: 85%;
    margin-left: 0;
    margin-top: 2em;
  }

  .landingImageWrapper img {
    width: 130%;
  }

  .landingTexts {
    width: 100%;
  }

  .contactUs {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .landingTexts h1 {
    font-size: 12vw;
  }

  .Section3DWrapper {
    grid-template-rows: auto auto 150px;
  }

  .Section3DWrapper .section h2,
  .Section3DWrapper .section p {
    width: auto;
  }

  .Section3DWrapper .sideImage {
    position: relative;
    bottom: 3em;
    width: 100%;
    right: 0%;
    overflow: visible;
    text-align: center;
  }

  .sideImage img {
    width: 100%;
    object-fit: contain;
  }

  .blogCardImg {
    height: 45vw;
  }

  .blogCard h3 {
    text-align: left;
    color: var(--black);
    font-size: 22px;
  }

  .grid3Dicons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.grid3Dicons h2{
  padding-left: 0em;
}


  .grid3DSection {
    grid-template-columns: 1fr;
  }

  .grid3DSection p {
    width: auto;
  }

  .grid3DSection h2 {
    text-align: center;
    padding: 0.5em 0;
  }

  .sectionIntro h2 {
    width: 100%;
    text-align: center;
  }

  .sectionIntro p {
    width: 90%;
    text-align: center;
  }

  .columnSection ul {
    padding: 0;
    margin-left: 0;
  }

  .productGrid {
    padding: 2em 0em;
  }
  .productGrid .img {
    padding: 0em;
    margin-top: 2em;
    width: 100%;
  }
  a .productGrid .productContent {
    padding: 0em;
  }

  .productContent h3 {
    font-size: 1.5em;
    margin-bottom: 1em;
  }

  .productContent h5 {
    font-size: 1em;
    margin-bottom: 1em;
  }

  .moreProductsColumns {
    grid-template-columns: 1fr;
  }

  .extraProduct {
    width: 100%;
    padding: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .EUGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3em;
    align-items: center;
    padding: 0em;
  }

  .extraProductLeft,
  .extraProductRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-right: 0em;
  }
  .columnsOrder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .projectContainer {
    align-items: center;
    display: flex;
    /* grid-template-columns: 1fr; */
    padding: 3em;
    max-width: 100%;
  }

  .Project {
    align-items: center;
    margin: 5em 0;
  }

  .projectsSection .Project h3 {
    font-size: 1.5em;
  }
  .projectsSection .Project h5 {
    font-size: 1.5em;
  }

  .projectContainer :nth-last-child(even) .Project {
    text-align: center;
    margin: 5em 0;
  }

  .projectContainer :nth-last-child(odd) .Project {
    text-align: center;
    margin: 5em 0;
  }

  .projectSingleImage {
    height: 7vh;
    padding: auto 0;
  }

  .euCompanies {
    flex-direction: column;
    align-items: center;
  }

  .euCompanies img {
    margin: 2em 0;
  }

  .awards {
    flex-direction: column;
    align-items: center;
  }

  .awards img {
    width: 30%;
    height: 30%;
    margin: 1vh 0;
  }

  .projectSingleImage {
    display: none;
  }

  .projectSingleImageMobile {
    display: inline-block;
    width: 100%;
    padding: auto 0;
  }
}
