/* Modal.css */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  }

  .modal-body {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center; /* Center content vertically */
    width: 100%;
  }
  
  .close-button {
    position: relative;
    width: 105px;
    height: 30px;
    bottom: 0px;
    right: -350px;
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    background-color: #10b070;
    color: white;
  }
  