.secondMenu {
  width: 100%;
  padding: 0 7vw 0 7vw;
  background-color: var(--white);
  position: absolute;
  color: var(--black);
  z-index: 999;
  left: 0;
  top: 72px;
  border-top: 2px solid var(--primaryGreen);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  justify-content: center;
}
.secondMenu a {
  margin: 0;
}

.activeTab {
  font-weight: 500;
  color: var(--primaryGreen) !important;
}
