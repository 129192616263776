

.aboutSection {
  padding: 10vw 7vw 7vw 7vw;
  z-index: -1;
}

.aboutSectionWhat {
  z-index: -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "whatDrivesUsText whatDrivesUsImageWrapper";
  gap: 6em;
}

.aboutSectionJourney {
  padding-top: 7em;
  z-index: -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "whatDrivesUsImageWrapper whatDrivesUsText ";
  gap: 6em;
}

.aboutLanding {
  /* display:grid;
    grid-template-columns:1fr 1fr;
    gap: 6em;
    padding: 4vw 3em 4vw 4vw; */
  height: 70vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutLanding div p {
  line-height: 1.7;
  padding: 4em 0;
}

.aboutLanding img {
  object-fit: cover;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  margin: 0;
  padding: 0;
  z-index: 0;
}

.aboutLandingImageWrapper {
  position: relative;
  height: 100%;
}

.aboutLandingText {
  position: absolute;
  width: 60%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutLandingText h2 {
  max-width: 90%;
  margin-top: 1em;
  font-size: 3.5em;
}
.aboutLandingText h4 {
  max-width: 75%;
  color: #585c61;
}
/* .aboutLandingImageWrapper img{
    margin: 0 auto;
    width:100%;
    height:auto;
    position:relative;
}  */

.whatDrivesUsImageWrapper {
  grid-area: whatDrivesUsImageWrapper;
  position: relative;
  height: 100%;
  padding: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}

.whatDrivesUsImageWrapper img {
  width: 100%;
  height: auto;
  position: relative;
  top: -4em;
  z-index: 1;
  border-radius: 7px;
}

.whatDrivesUsText {
  grid-area: whatDrivesUsText;
  text-align: left;
}

.quoteImage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2em 0;
}

.quoteNumbersGrid {
  margin: 5em auto 0 auto;
  display: grid;
  width: 80%;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.whatDrivesUsImageWrapper .square {
  width: 100px;
  height: 200px;
  position: absolute;
}

.sTop {
  top: 0;
  left: -1.5em;
  z-index: 0;
  background-color: var(--secondaryBlue);
}

.sBottom {
  width: 200px !important;
  top: auto;
  bottom: 2.5em;
  right: -1.5em;
  z-index: 0;
  background-color: var(--secondaryGreen);
  transition: 0.3s ease all;
  /* animation: 2s moveSquares  infinite; */
}

.quote-mobile {
  display: none;
}

.quoteImage .quote {
  width: 70%;
  margin: 0 auto;
  padding: 0;
}

/*MOBILE VERSION*/
@media only screen and (max-width: 800px) {
  .aboutLanding {
    z-index: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 40vh;
  }

  .aboutSection {
    padding: 0;
  }

  .aboutSectionWhat {
    z-index: 0;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }

  .aboutSectionJourney {
    z-index: 0;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }

  .whatDrivesUsText {
    text-align: left;
  }

  .whatDrivesUsImageWrapper {
    padding: 1.5em;
  }

  .whatDrivesUsImageWrapper img {
    top: 0em;
    bottom: -4em;
  }

  .whatDrivesUsImageWrapper .square {
    display: none;
  }

  .quote-mobile {
    display: flex;
  }

  .quote {
    display: none;
  }

  .quoteImage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .quoteImage .quote-mobile {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .quoteNumbersGrid {
    z-index: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .quoteNumbersGridElement {
    margin: 1.5em;
  }

  .quoteNumbersGridElement h1 {
    font-size: 3em;
  }

  .quoteNumbersGridElement h2 {
    font-size: 2.5em;
    color: var(--textColor);
  }

  .aboutLanding {
    min-height: 60vh;
  }

  .aboutLandingText {
    width: 90%;
  }

  .aboutLandingText h1 {
    margin-top: 0em;
    font-size: 2.5em;
  }
  .aboutLandingText h3 {
    max-width: 100%;
    font-size: 1em;
    font-weight: 300;
  }
}
