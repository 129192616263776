.section {
  padding: var(--padding);
}

.smallTitle {
  margin: 0 0 0.5em 0;
  font-size: 2em;
}

.greenSection {
  /* background: radial-gradient(
    121.16% 101.16% at 100% 11.54%,
    var(--lightGrey) 0%,
    var(--primaryGreen) 100%
  ); */
  background: var(--backgroundGreenLight);
  width: 100vw;
  color: "var(--white)";
  position: relative;
  z-index: 2;
}

.sectionAngle {
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0% 4em);
  clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0% 4em);
  padding-top: 7em;
}

.reverseAngle {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
}
