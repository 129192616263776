.recaptcha-container {
    margin-bottom: 16px;
    padding: 8px;
  }
  
  .recaptcha-container.error {
    border: 1px solid red;
    padding: 8px;
  }
  
  .error-message {
    color: red;
    margin-top: 8px;
  }
  
  input.error, select.error, textarea.error, .recaptcha-container.error {
    border: 2px solid red;
  }
  
  .error-message1 {
    color: red;
    font-size: 0.875em;
    margin-top: 0.5em;
  }