.productLanding {
    padding: 0em 0 0 7vw;
    min-height: 40em;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.nav .productLogo img {
    width: 100%;
    margin-top: 4em;
}

.productLanding .Texts h1 {
    width: 100%;
}

.productLanding .img {
    width: 100%;
}

.productLanding .img img {
    width: 100%;
}

.Texts .button{
    margin-left: 1em;
}

.whatsSection {
    padding-top: 7vw;
}

.whatsSection ul li:nth-last-child(even) .singleWhat .img {
    grid-area: 1;
}


.whatsSection .sectionIntro h2 {
    padding-left: 12vh;
    width: 90%;
    margin-bottom: .5em;
    z-index: 10000;
    position: relative;
}

.whatsSection .sectionIntro h4 {
    padding-left: 12vh;
}

.whatsSection .sectionIntro p {
    padding-left: 12vh;
    width: 90%;
    font-weight: 400;
}

.whatsWrapper {
    margin-top: 6em;
}
.whatsSection ul {
    padding-inline-start: 0;
}
.singleWhat {
    margin: 3em 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    align-items: center;
}

.singleWhatText{
    padding: 5em;
}

.singleWhat .img {
    overflow: hidden;
}

.singleWhat .img img {
    width: 100%;
}

.costsGrid {
    margin-top: 5em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

@media only screen and (max-width:768px) {
    .productLanding {
        grid-template-columns: 1fr;
        height: auto;
        padding: 0 1em;
        margin: 0;
        display: block;
        min-height: 50vh;
    }

    .productLanding .img {
        min-height: 60vh;
    }

    .productNav {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 2em 0;
    }

    .links{
        display: none;
    }
    .Texts{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .Text input {
        padding: 0em;
    }
    .Texts .button {
        margin: 0 0 2em 0;
    }

.whatsSection ul {
    padding-inline-start: 0;
}

.singleWhatText{
    padding: 2em;
}

.whatsSection .sectionIntro h2 {
    padding: 0 7vw;
    width: 100%;
    margin-bottom: .5em;
    z-index: 10000;
    position: relative;
    text-align: center;
}

.whatsSection .sectionIntro p {
    padding: 1em 7vw;
    width: 100%;
    font-weight: 400;
    text-align: center;
}

.whatsSection .sectionIntro h4 {
    padding-left: 0;
}
}