.jobApplyGrid {
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 4em;
}

.detail {
  border-top: 1px solid var(--primaryGreen);
  padding: 1em 0;
}

.greenTitle {
  color: var(--primaryGreen);
  font-weight: 400;
}
.uppercase {
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .jobApplyGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5em;
  }
}
