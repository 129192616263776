p {
  line-height: 1.7;
}

.internsLanding {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6em;
  padding: 7vw 3em 0 7vw;
}
.internsLanding div h1 {
  margin-top: 0.5em;
}

.internsLanding div p {
  line-height: 1.7;
  margin: 2em 0;
}
.internsLandingImageWrapper {
  position: relative;
  height: 100%;
}

.internsLandingImageWrapper img {
  width: 100%;
  height: auto;
  position: relative;
  top: -4em;
}
.internsLandingImageWrapper .square {
  width: 100px;
  height: 200px;
  position: absolute;
}
.sTop {
  top: 0;
  left: -1.5em;
  z-index: -1;
  background-color: var(--secondaryBlue);
}

.sBottom {
  width: 200px !important;
  top: auto;
  bottom: 2.5em;
  right: -1.5em;
  z-index: -1;
  background-color: var(--primaryGreen);
  transition: 0.3s ease all;
  /* animation: 2s moveSquares  infinite; */
}

@keyframes moveSquares {
  0% {
    bottom: 2.5em;
    right: -1.5em;
  }
  25% {
    bottom: 3em;
    right: -1em;
  }
  50% {
    bottom: 2.5em;
    right: -0.5em;
  }
  50% {
    bottom: 2em;
    right: -1em;
  }

  100% {
    bottom: 2.5em;
    right: -1.5em;
  }
}

.internsSection {
  padding: 8vw 7vw 7vw 7vw;
}

.internsSectionWhat {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6em;
}

.reasonsWhyGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minMax(300px, 1fr));
  gap: 1em;
  padding-top: 4em;
}

.WhyCards {
  padding: 3em 2em;
  background-color: var(--white);
  border-radius: 3px;
  transition: 0.3s all ease;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.75) 100%
  );
}
.WhyCards:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.WhyCards h4 {
  margin-bottom: 0.7em;
}

.AngleTop {
  -webkit-clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 100%);
}

.carrouselCardContent {
  position: relative;
}
.carrouselCardContent .aboutPerson {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
}
.carrouselCardContent div h2,
.carrouselCardContent div h4 {
  background-color: var(--white);
  display: inline-block;
  padding: 0 0.4em 0 0.2em;
  clear: both;
}
.carrouselCardContent div h4 {
  color: #585c61;
  font-weight: lighter;
  padding: 0.5em 1em 0.5em 0.5em;
}

.CCCWraper p {
  text-align: left;
}

h4 {
  margin: 0;
}
.control-arrow {
  height: 6em;
  top: 30% !important;
}

@media only screen and (max-width: 768px) {
  .internsLanding {
    grid-template-columns: 1fr;
  }
  .internsSectionWhat {
    display: block;
    grid-template-columns: 1fr;
  }
  .internsSectionWhat div {
    /* margin-bottom:3em; */
  }
  .internsSection {
    padding: 15vw 7vw;
  }
  .AngleTop {
    -webkit-clip-path: polygon(0 0, 100% 3%, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 3%, 100% 100%, 0 100%);
  }
}
