.policy_Section {
  max-width: 1500px;
  padding: 20px 20px 20px 20px;
  margin: auto;
}

.policy_header {
  text-align: center;
  padding-top: 20px;
  border-bottom: 2px solid gray;
  padding-bottom: 20px;
}
.policy_button {
  display: flex;
  gap: 2rem;
  padding: 0px 10px 10px 10px;
}
.policy_button button:active {
  transform: scale(0.8);
  border: 1px solid #bedce8;
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  transition: all 0.1s;
}
.policy_section {
  padding-top: 50px;
}

.section_start .title {
  font-size: 22px;
  font-weight: 400;
  text-transform: capitalize;
  border-bottom: 2px solid gray;
  padding-bottom: 10px;
  max-width: 500px;
}

.section_start .main_text {
  padding-top: 20px;
  text-align: justify;
  font-weight: 400;
}

.section_start .list_text {
  padding-top: 0px;
  text-align: justify;
  font-size: 16px;
}

a {
  color: var(--primaryGreen);
  font-weight: 500;
}
.privacy_list li {
  margin-top: 10px;
  font-weight: 600;
  text-align: justify;
}
ul li {
  list-style: none;
}
.extra_list {
  padding-left: 50px;
}
.version p {
  font-size: 18px;
  font-weight: bold;
}
.Address {
  margin-top: 10px;
  max-width: 200px;
}
